import PropTypes from "prop-types"
import React from "react"
import { Reset, SoundButton } from "./windows";
import MyClock from './clock';

import Apple from '../../static/Apple_icon.svg';
import Styles from '../scss/header.module.scss';


const Header = (props) => (
  <header
    id="header"
    className={Styles.header}
  >
    <div className={Styles.left}>
      <div className={Styles.logo}>
        <a href="https://souldesign.gr" target="_blank" rel="noreferrer">
          <Apple /> Soul in Quarantine
        </a>
      </div>
      <Reset {...props}/>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI('http://quarantine.souldesign.gr/')}&t=${encodeURI(props.siteTitle)}`}
        onClick={(event) => {
          typeof window === 'object' && window.open(event.target.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
          return false;
        }}
        target="_blank" title="Share on Facebook"
      >
        Share
      </a>
      <a
        href="https://souldesign.gr"
        target="_blank" title="Visit Soul"
        className={Styles.hiddenMobile}
      >
        Visit Soul
      </a>
    </div>
    <SoundButton />
    <MyClock className={Styles.clock} format='hh-mm' hour12={false} />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Soul in Quarantine`,
}

export default Header
