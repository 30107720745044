import "../scss/style.scss"
import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import CookiePopup from "./cookiepopup"
import { SoundsProvider } from "../context/SoundsContext"
import CookieConsentContext from "../context/CookieContext"

const Layout = (props) => {
  const {children} = props
  return (

    <CookieConsentContext.Consumer>
      {({ state, dispatch, popupIsOpen, setPopupIsOpen }) => (
        <SoundsProvider>
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 'none'
            }}
          >
            <Header {...props} />
            <main className="main" style={{
              height: 'calc(100vh - 80px)',
              marginTop: 40
            }}>{children}</main>
          </div>
          <Footer setPopupIsOpen={setPopupIsOpen} />
          <div>
          {popupIsOpen && <CookiePopup dispatch={dispatch} value={state} />}
          </div>
        </SoundsProvider>
      )}
      </CookieConsentContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
