import React, { useEffect, useState, useContext, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import $ from "jquery";
import Window from "./window"
import Delay from 'react-delay'
import VolumeOffRoundedIcon from '../../static/Sound_off.svg';
import VolumeUpRoundedIcon from '../../static/Sound_on.svg';

import Sound from 'react-sound';
import {SoundsContext, SoundsDispatchContext} from "../context/SoundsContext"

import UIfx from 'uifx';
import closeSound from '../../static/close.mp3';

const Windows = (props) => {

  const {allWindow} = useStaticQuery(graphql`
    query WindowsQuery {
      allWindow {
        nodes {
          id,
          img,
          width
        }
      }
    }
  `);

  const { nodes } = allWindow;

  const [zindex, setZindex] = useState(0)

  const sounds = useContext(SoundsContext);

  const close = useRef(new UIfx(closeSound,{
    volume: 1,
    throttleMs: 100
  }));

  const setActive = (e) => {
    setZindex(zindex+1)
    $(e.target).closest('.window').css('z-index', zindex);
  }

  useEffect(() => {
    props.setComplete(Object.values(nodes).length === props.closed.length)
  }, [props.closed])

  return (
    <>
        <div>
        {!props.complete
          ? (<Sound
          url="./music.mp3"
          playStatus={sounds.enabled?"PLAYING":"PAUSED"}
          autoload={sounds.enabled}
          loop={true} />)
          : (<Sound
            url="./flush.mp3"
            playStatus={sounds.enabled?"PLAYING":"PAUSED"}
            autoload={sounds.enabled}
            loop={false} />)
        }
        </div>
        {Object.values(nodes).map( (node, i) => {
          if(props.closed.indexOf(i) > -1){
            return null;
          }
          return (<Delay
           wait={i*500}
           key={i}>
           <Window
             image={node.img}
             defaultClassName="window"
             onMouseDown={setActive}
             width={node.width}
             complete={props.complete}
             close={(e) => {
               e.preventDefault();
               props.setClosed([i, ...props.closed])
               if(sounds.enabled){
                 close.current.play()
               }
             }}
            />
         </Delay>)
     })}
   </>
  )
}

export default Windows;

const Reset = (props) => {
    return (
      <span
        onClick={() => {
          props.setClosed([]);
          props.setReset(true);
        }}
        style={{
          cursor: 'pointer'
        }}
      >
        Replay
      </span>
    )
}

const SoundButton = () => {

    const sounds = useContext(SoundsContext);
    const setSounds = useContext(SoundsDispatchContext);

    return (
      <span
        onClick={() => {
          const status = !sounds.enabled
          setSounds({enabled: status})
        }}
        style={{
          cursor: 'pointer'
        }}
      >
        {!sounds.enabled ? <VolumeOffRoundedIcon style={{width: 24}} /> : <VolumeUpRoundedIcon  style={{width: 24}} />}
      </span>
    )
}

export {
  Windows,
  Reset,
  SoundButton
}
