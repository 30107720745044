import React from "react"
import Styles from "../scss/cookiepopup.module.scss"
import { Link } from "gatsby"

const CookiePopup = ({ value, dispatch }) => {
  return (
    <div className={Styles.popup}>
      <p>Χρησιμοποιούμε cookies για να βελτιώσουμε την online εμπειρία σας, να αναλύουμε την επισκεψιμότητα στον διαδικτυακό τόπο μας κ.λπ.
        Πατώντας Αποδέχομαι μας δίνετε τη συγκατάθεσή σας να ενεργοποιήσουμε τα cookies απόδοσης.
        Αν δεν επιθυμείτε να τοποθετήσουμε τα cookies απόδοσης πατήστε Δεν αποδέχομαι. Μάθετε περισσότερα σχετικά με τα cookies πατώντας <Link to={"/cookies-policy"}>εδώ</Link>.
      </p>
      <div className={Styles.actions}>
        <button
          className={Styles.button}
          onClick={() => dispatch({ type: "declineAll" })}
        >
          Δεν αποδέχομαι
        </button>
        <button
          className={Styles.button}
          onClick={() => dispatch({ type: "acceptAll" })}
        >
          Αποδέχομαι
        </button>

      </div>
    </div>
  )
}

export default CookiePopup
