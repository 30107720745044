import React from "react"
import Styles from "../scss/footer.module.scss"
import { Link } from "gatsby"

const Footer = (props) => (
  <div className={Styles.footer}>
    © Soul Creative Agency 2020
    <div className={Styles.links}>
      <Link to="/terms-of-use">Όροι χρήσης</Link>
      <Link to="/privacy-policy">Πολιτική απορρήτου</Link>
      <Link to="/cookies-policy">Πολιτική cookies</Link>
      <span
        onClick={() => {
        props.setPopupIsOpen(true)
      }}>Ρυθμίσεις cookies</span>
    </div>
  </div>
)

export default Footer
