import React, { useState, useEffect } from 'react'
import { Rnd } from "react-rnd";
import Image from './image'

export default props => {

  const ref = React.useRef();

  let rnd;

  const [position] = useState({
    x: 0,
    y: 0
  })

  const [loaded, setLoaded] = useState(false)

  const update = () => {
    if(typeof window === 'undefined'){
      return
    }
    let inst = ref.current;

    if(inst.imageRef){
      inst = inst.imageRef.current;
    }

    setLoaded(true)

    const [width, height] = [window.innerWidth, window.innerHeight];

    const {offsetWidth, offsetHeight} = inst;

    rnd.updatePosition({
       x: randomIntFromInterval(0, (width - offsetWidth)),
       y: randomIntFromInterval(document.getElementById('header').offsetHeight + 40, (height - Math.min(offsetHeight, height)))
    });
  }

  useEffect(() => {

    let inst = ref.current;

    if(inst.imageRef){
      inst = inst.imageRef.current;
    }

    if(inst && inst.complete){
      update()
    }

  }, [])


  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  return (
    <Rnd
     ref={c => { rnd = c; }}
     onMouseDown={props.onMouseDown}
     bounds=".main"
     className={props.defaultClassName}
     lockAspectRatio="true"
     minWidth="300"
     maxHeight="100%"
     style={loaded ? {} : {opacity: 0}}
     default={{
        x: position.x,
        y: position.y,
        width: typeof window !== 'undefined' ? Math.max((window.innerWidth*parseInt(props.width)/100), 300) : props.width
      }}
     >
      <div className={`${props.defaultClassName}__header`}>
        <div aria-label="Close"
        onClick={(e) => {props.close(e);}}
        className={`${props.defaultClassName}__close`}></div>
      </div>
      <div className={`${props.defaultClassName}__content`}>
        <Image ref={ref} load={update} filename={props.image} />
      </div>
    </Rnd>
  )
}
