import React from 'react';
import Clock from 'react-digital-clock';

class MyClock extends Clock {

  render(){
    return(
      <div className={this.props.className}>
        <span style={{padding: "4px"}}>{this.state.time}</span>
      </div>
    );
  }
}

export default MyClock